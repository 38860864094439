import React, {useEffect} from 'react'
import Hero from '../components/Project/FriendshipII/Hero'
import Descreption from '../components/Project/FriendshipII/Descreption'


const FriendshipII = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
    <Hero />
    <div className="pt-16 px-[3%] md:px-[6%]">
    <Descreption />
    
    </div>
    </>
  )
}

export default FriendshipII