import React from 'react';
import { FaChargingStation , FaHospital, FaSchool, FaHotel } from 'react-icons/fa';
import { MdAccountBalance, MdShoppingBasket,  MdLocalMovies, MdLocalCafe, MdLocalPharmacy, MdConnectingAirports } from 'react-icons/md';


const Neighbour = () => {
  return (
    <div>
      <div className="p-8">
        <div className="text-center">
          <div className="relative flex flex-col items-center pt-8">
            <h1 className="text-5xl font-bold leading-tight ">
              {' '}
              Find <span className="text-secondary">in your neighbourhood</span>{' '}
            </h1>
          </div>
        
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-8 sm:grid-cols-2 md:grid-cols-3 pl-4">
        <div className="p-2">
          <div className="inline-block text-secondary ">
            <FaHospital size="2em" />
          </div>
          <h2 className="uppercase text-black font-semibold mb-2"> HOSPITAL </h2>
        </div>
        <div className="p-2">
          <div className="mx-1  text-secondary  inline-block ">
            <FaChargingStation size="2em" />{' '}
          </div>
          <h2 className="uppercase  text-black font-semibold mb-2"> GAS STATION </h2>
          
        </div>
        <div className="p-2">
          <div className="text-secondary   inline-block">
            {' '}
            <MdAccountBalance size="2em" />{' '}
          </div>
          <h2 className="uppercase  text-black font-semibold mb-2"> BANK </h2>
          
        </div>
        
        <div className="p-2">
          <div className="inline-block text-secondary ">
            <MdLocalCafe size="2em" />
          </div>
          <h2 className="uppercase text-black font-semibold mb-2"> CAFE</h2>
          
        </div>
        <div className="p-2">
          <div className="mx-1  text-secondary  inline-block ">
            <MdLocalPharmacy size="2em" />{' '}
          </div>
          <h2 className="uppercase  text-black font-semibold mb-2"> PHARMACY </h2>
         
        </div>
        <div className="p-2">
          <div className="text-secondary   inline-block">
            {' '}
            <MdShoppingBasket size="2em" />{' '}
          </div>
          <h2 className="uppercase  text-black font-semibold mb-2"> SUPER MARKET </h2>
        
        </div>

        <div className="p-2">
        <div className="inline-block text-secondary ">
          <MdConnectingAirports size="2em" />
        </div>
        <h2 className="uppercase text-black font-semibold mb-2"> AIRPORT</h2>
       
      </div>
      <div className="p-2">
        <div className="mx-1  text-secondary  inline-block ">
          <FaSchool size="2em" />{' '}
        </div>
        <h2 className="uppercase  text-black font-semibold mb-2"> SCHOOL </h2>
        
      </div>
      <div className="p-2">
        <div className="text-secondary   inline-block">
          {' '}
          <FaHotel size="2em" />{' '}
        </div>
        <h2 className="uppercase  text-black font-semibold mb-2"> RESTAURANT </h2>
       
      </div>
      <div className="p-2">
        <div className="text-secondary   inline-block">
          {' '}
          <MdLocalMovies size="2em" />{' '}
        </div>
        <h2 className="uppercase  text-black font-semibold mb-2"> CINEMA </h2>
       
      </div>
        </div>
    </div>
  );
};

export default Neighbour;
