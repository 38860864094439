import React, {useEffect} from 'react'
import Hero from '../components/About/Hero'
import Why from '../components/About/Why'
import AboutUs from '../components/About/AboutUs';

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
  return (
    <>  
    <Hero />
    <div className="pt-16 px-[3%] md:px-[6%]">
    <AboutUs />
    <Why />
    </div>
  
 </>
  )
}

export default About