import React, { useEffect } from 'react';
import Footer from './components/common/Footer';
import Navbar from './components/common/Nav';
import Home from './pages/Home';
import About from './pages/About';
import { Routes, Route } from 'react-router-dom';
import Contact from './pages/Contact';
import Saromaria from './pages/Saromaria';
import Friendship from './pages/Friendship';
import Olompia from './pages/Olompia';
import Sheger from './pages/Sheger';
import FriendshipII from './pages/FriendshipII';

const App = () => {
  useEffect(() => {
    (async () => {
      const LocomotiveScroll = (await import('locomotive-scroll')).default;
      const locomotiveScroll = new LocomotiveScroll();
    })();
  }, []);
  return (
    <>
      <Navbar />
      <div className="min-h-screen pb-40 !scroll-smooth">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/tadesech" element={<Saromaria />} />
          <Route path="/werkeneh" element={<Friendship />} />
          <Route path="/olympia" element={<Olompia />} />
          <Route path="/nadew" element={<Sheger />} />
          <Route path="/yeshi" element={<FriendshipII />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default App;
