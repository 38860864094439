import React from 'react';
import { projects } from '../data/Project';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion'; 

const Projects = () => {
  return (
    <div className="pt-10 pb-16">
      <div className="text-center">
      <motion.h1 
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 1, delayChildren: 0.6 }}
      className="mx-auto sub-heading">our projects</motion.h1>
      <motion.h1 
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.8, delayChildren: 0.8 }}
      className="heading">
        Our reputation is our Pride, Explore our delivered projects
      </motion.h1>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-8 sm:grid-cols-2 md:grid-cols-3">
        {projects.map(({ id, name, number, image, link }) => (
          <motion.div 
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 1 }}
          key={id} 
          className="relative w-full group">
            <div className="overflow-hidden">
              <Link to={link} className="!opacity-100">
                <img
                  src={image}
                  alt={name}
                  className="w-full  h-fit md:h-[250px] object-cover group-hover:scale-125 transition-a"
                />
              </Link>
            </div>
            <div className="absolute bottom-0 left-0 w-full px-2 py-2 transition-transform bg-gradient-to-t from-black/80 text-slate-100 to-transparent">
              <h1 className="text-lg font-semibold">{name}</h1>
              <p>{number} Poperty</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Projects;

