import React, { useEffect } from 'react';
import Hero from '../components/Project/Frendship/Hero';
import Details from '../components/Project/Frendship/Details';
import Neighbour from '../components/Project/Frendship/Nearly';
import ImageGallery from '../components/Project/Frendship/ImageGallery';

const Friendship = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <>
      <Hero />
      <div className="pt-16 px-[3%] md:px-[6%]">
        <Details />
        <ImageGallery />
        <Neighbour />
      </div>
    </>
  );
};

export default Friendship;
