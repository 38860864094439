import React from 'react'
import img from '../../asssets/Contact us.jpg'

const Hero = () => {
  return (
   <>
   <div class="relative bg-gradient-to-r from-purple-600 to-blue-600 h-screen text-white overflow-hidden">
   <div class="absolute inset-0">
     <img src={img} alt="Background" class="object-cover object-center w-full h-full" />
     <div class="absolute inset-0 bg-black opacity-50"></div>
   </div>
   
   <div class="relative z-10 flex flex-col justify-center items-center h-full text-center">
     <h1 class="text-5xl font-bold leading-tight mb-4">Welcome to Our Contact Us Page.</h1>
     <p class="text-lg text-gray-300 mb-8">Get in touch with us.</p>
   </div>
 </div>
   </>
  )
}

export default Hero