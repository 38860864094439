import React, {useEffect} from 'react'
import Saro from '../components/Project/Saro/Saro'
import Descreption from '../components/Project/Saro/Descreption'
import Neighbour from '../components/Project/Saro/Neighbour'
import ImageGallery from '../components/Project/Saro/ImageGallery'

const Saromaria = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <>
    <Saro />
    <div className="pt-16 px-[3%] md:px-[6%]">
    <Descreption />
    <ImageGallery />
    <Neighbour />
    </div>
    </>
  )
}

export default Saromaria