import React from 'react';
import { Link } from 'react-router-dom';
import img from '../../asssets/Home.jpeg';
import img1 from '../../asssets/afro/Saro-Maria/ASR SM 02.jpg';
import img2 from '../../asssets/About us.jpeg';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <div className="py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
      <div className="text-center pb-5">
        <motion.h1 
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.3, delayChildren: 0.3 }}
        className="mx-auto sub-heading">About Us</motion.h1>
        <motion.h1 
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.6, delayChildren: 0.6 }}
        className="heading">We build palaces, fit for Royals</motion.h1>
      </div>
      <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div>
              <motion.p
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.3 }}
                className="leading-loose  text-blueGray-800 p-2"
              >
                Our journey started when our founders who spent many years
                living in Sweden, hoped to purchase real estate in their
                homeland. Unfortunately, the continual disappointment they faced
                in building quality, delivery time, unfulfilled promises,
                contract breaches, and much more, were extremely frustrating.
              </motion.p>
              <motion.p
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.6 }}
                className="py-2 leading-loose  text-blueGray-800 p-2"
              >
                This led to our four founders joining their expertise to bring
                the same level of absolute quality and pride in craftsmanship
                they admired in the Swedish real estate industry. Thus, giving
                birth to Afrosweden Real Estate PLC.
              </motion.p>
              <motion.p
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.9 }}
                className="py-2 leading-loose  text-blueGray-800 p-2"
              >
                When we launched our project our foremost priority was that our
                clients can relax in peace from the moment they make their
                initial installment, knowing a capable &amp; responsible company
                is building their Palaces on their behalf. We are proud to say
                we have accomplished this from our get-go and it is still our
                highest priority that our clients lead a stress free life with
                all their concerns and needs taken care of by us.
              </motion.p>
              <hr className="w-full my-6 border-gray-300" />
              <Link to="/about">
                <motion.button
                  whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                  transition={{ duration: 1 }}
                  className="mt-4 btn btn-primary"
                >
                  read more
                </motion.button>
              </Link>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5">
          <motion.img
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.4, delayChildren: 0.6 }}
            className="object-cover w-full h-56 col-span-2 rounded shadow-lg"
            src={img}
            alt=""
          />
          <motion.img
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.6, delayChildren: 0.8 }}
            className="object-cover w-full h-48 rounded shadow-lg"
            src={img1}
            alt=""
          />
          <motion.img
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.8, delayChildren: 1 }}
            className="object-cover w-full h-48 rounded shadow-lg"
            src={img2}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default About;
