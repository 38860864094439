import React, {useEffect} from 'react'
import Map from '../components/Contact/Map'
import Hero from '../components/Contact/Hero'
import ContactUs from '../components/Contact/ContactUs'

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
  return (
   <>
   <Hero />
   <div className="pt-16 px-[3%] md:px-[6%]">
   <Map />
   <ContactUs />
   </div>
   </>
  )
}

export default Contact