import React from 'react'
import './style.css'


const Saro = () => {
  return (
    <>
    <section class=" section conten">
    <div class="outer">
     <div class="details">
         <h1 class="text-5xl font-bold leading-tight mb-4 text-primary">TADESECH SITE</h1>
 </div>
  </div>
</section>
</>
  )
}

export default Saro