import React, {useEffect} from 'react'
import Hero from '../components/Project/Olompia/Hero'
import Descreption from '../components/Project/Olompia/Descreption';
import Neighbour from '../components/Project/Olompia/Neighbour';
import ImageGallery from '../components/Project/Olompia/ImageGallery';

const Olompia = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
  return (
    <>
    <Hero />
    <div className="pt-16 px-[3%] md:px-[6%]">
    <Descreption />
    <ImageGallery />
    <Neighbour />
    </div>
    </>
  )
}

export default Olompia