import React from "react";
import { testimonials } from "../data/Project";
import {motion} from 'framer-motion'

const Testimonials = () => {


  return (
    <section className="flex items-center py-8">
   
    <div className="max-w-6xl px-4 mx-auto">
    <div className="text-center pb-4">
    <motion.h1 
    whileInView={{ x: [-100, 0], opacity: [0, 1] }}
    transition={{ duration: 0.8 }}
    className="mx-auto sub-heading">Testimonials</motion.h1>
    <motion.h1 
    whileInView={{ x: [-100, 0], opacity: [0, 1] }}
    transition={{ duration: 1 }}
    className="heading">Here from our clients</motion.h1>
  </div>
        <div className="flex flex-wrap justify-center mb-3 -mx-3">
        {testimonials.map((data) => (
          <motion.div 
          whileInView={{ opacity: [0, 1] }}
          transition={{ duration: 1, delayChildren: 1 }}
          id={data.id} className="w-full px-3 mb-6 md:w-1/2 lg:w-1/3">
          <div className="p-8 bg-white rounded shadow ">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  className="w-10 h-10 text-blue-500  bi bi-quote" viewBox="0 0 16 16">
                  <path
                      d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z" />
              </svg>
              <p className="mb-4 leading-loose  text-blueGray-400">
                 {data.reviewText}
                 </p>
              <div className="flex items-center ">
               {/**   <img className="object-cover rounded-full w-14 h-14"
                      src={data.image} alt="" /> */} 
                  <div className="pl-4">
                      <p className="text-lg font-semibold">{data.name}</p>
                      <p className="text-sm text-blue-600">{data.role}</p>
                  </div>
              </div>
          </div>
      </motion.div>
  ))}
            
           
           
        </div>
       
    </div>
</section>
  );
};

export default Testimonials;