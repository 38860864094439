import React from 'react';
import { AiOutlinePaperClip } from 'react-icons/ai';

const Descreption = () => {
  return (
    <div>
      <div className="px-4 sm:px-0">
        <h3 className="font-semibold leading-7 text-secondary text-3xl">
          Project Information
        </h3>
        <p className="mt-1 max-w-2xl text-md leading-6 text-gray-500">
        TADESECH Site.
        </p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-lg font-medium leading-6 text-gray-900">
              Project Name
            </dt>
            <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            Mrs Tadesech Site
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-lg font-medium leading-6 text-gray-900">
              Location
            </dt>
            <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              Saro-Maria / Bole / Addis Ababa
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-lg font-medium leading-6 text-gray-900">
              Project Duration
            </dt>
            <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              Finishing Stage
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-lg font-medium leading-6 text-gray-900">
              Apartment Specifics
            </dt>
            <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              Deluxe Living Room, 2 Bathrooms, 3 Bedrooms, Housekeeper's
              Room/Storage, Spacious Corridor, Private Emergency Exit,
              Exclusively 2 Units Per Floor
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-lg font-medium leading-6 text-gray-900">
              Project Details
            </dt>
            <dd className="mt-1 text-md leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            - High-end terrace-style spa <br />
            - Access to Bole main road <br />
            - Fully equipped terrace gym <br />
            - Soundproof double-glazed windows and doors <br />
            - Spacious, naturally ventilated & ample natural light <br />
            - Excellent city view of Bole urban enclave <br />
            - Equipped with two way video and audio intercom video entrance system <br />
            - Broadband with fiber optics & Full Ethernet apparatus installed <br />
            - Video-aided security system in all communal areas <br />
            - 24/7 Security Provided <br />
            - High yield back up generator <br />
            - Maximum capacity water reservoir <br />
            - State of the art fire alarm system <br />
            - Only 5 minutes from Bole International Airport
            
            </dd>
          </div>

          {/*   <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-lg font-medium leading-6 text-gray-900">
              Attachments
            </dt>
            <dd className="mt-2 text-md text-gray-900 sm:col-span-2 sm:mt-0">
              <ul
                role="list"
                className="divide-y divide-gray-100 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <AiOutlinePaperClip
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">
                        site-plan.pdf
                      </span>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href="#"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Download
                    </a>
                  </div>
                </li>
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                  <div className="flex w-0 flex-1 items-center">
                    <AiOutlinePaperClip
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">
                        flor-plan.pdf
                      </span>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href="#"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Download
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div> **/}
        </dl>
      </div>
    </div>
  );
};

export default Descreption;
