import React from 'react';
import img from '../../asssets/Home.jpeg';
import img1 from '../../asssets/afro/Saro-Maria/ASR SM 02.jpg';
import img2 from '../../asssets/afro/Saro-Maria/ASR3.jpeg';

const Why = () => {
  return (
    <>
      <section className="pt-2 lg:pt-[10px] pb-12 lg:pb-[90px] overflow-hidden">
        <div className="container">
          <div className="flex flex-wrap items-center justify-between -mx-4">
            <div className="w-full px-4 lg:w-6/12">
              <div className="flex items-center -mx-3 sm:-mx-4">
                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  <div className="py-3 sm:py-4">
                    <img src={img} alt="" className="w-full rounded-2xl" />
                  </div>
                  <div className="py-3 sm:py-4">
                    <img src={img1} alt="" className="w-full rounded-2xl" />
                  </div>
                </div>
                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  <div className="relative z-10 my-4">
                    <img src={img2} alt="" className="w-full rounded-2xl" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
              <div className="mt-5 lg:mt-0">
                <p className="mt-6 mb-10 text-base leading-7 text-gray-900">
                  Exclusively located in Bole, the financial and cultural hub of
                  Addis. Our luxury apartments are deserving of the title
                  palaces. Built to the highest standard and the finest
                  workmanship, the sheer quality of our apartments is unrivaled.
                  Coupled with ever-present amenities in all our projects such
                  as fully equipped gym, luxurious spa, ample parking space and
                  much more Afrosweden Real Estate truly offers you the keys to
                  a better living.
                </p>

                <p className="mt-6 mb-10 text-base leading-7 text-gray-900">
                  Prioritizing the need of our customers and your quality of
                  living we exclusively only build 2 units per floor, the
                  quality of your life & privacy always comes first. This care
                  to our craft & your comfort is also reflected in our
                  meticulous designs that make sure natural lighting &
                  refreshing ventilation follows you in every corner of your
                  palace.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Why;
