import React, { useState, useEffect, useRef } from 'react';
import am1 from '../../../asssets/afro/Olompia/ASR O 01.jpg'
import am2 from '../../../asssets/afro/Olompia/ASR O 02.jpg'
import am3 from '../../../asssets/afro/Olompia/ASR O 03.jpg'
import am4 from '../../../asssets/afro/Olompia/ASR O 04.jpg'
import am5 from '../../../asssets/afro/Olompia/ASR O 05.jpg'
import am6 from '../../../asssets/afro/Olompia/ASR O 06.jpg'


function ImageGallery() {
  const [imageGalleryOpened, setImageGalleryOpened] = useState(false);
  const [imageGalleryActiveUrl, setImageGalleryActiveUrl] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(null);

  const imageGalleryOpen = (event) => {
    const { index, src } = event.target.dataset;
    setActiveImageIndex(parseInt(index));
    setImageGalleryActiveUrl(src);
    setImageGalleryOpened(true);
  };

  const imageGalleryClose = () => {
    setImageGalleryOpened(false);
    setTimeout(() => setImageGalleryActiveUrl(null), 300);
  };

  const imageGalleryNext = () => {
    if (activeImageIndex === galleryRef.current.childElementCount) {
      setActiveImageIndex(1);
    } else {
      setActiveImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const imageGalleryPrev = () => {
    if (activeImageIndex === 1) {
      setActiveImageIndex(galleryRef.current.childElementCount);
    } else {
      setActiveImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const galleryRef = useRef(null);

  useEffect(() => {
    const imageGalleryPhotos = galleryRef.current.querySelectorAll('img');
    for (let i = 0; i < imageGalleryPhotos.length; i++) {
      imageGalleryPhotos[i].setAttribute('data-index', i + 1);
    }
  }, []);

  useEffect(() => {
    if (activeImageIndex !== null) {
      setImageGalleryActiveUrl(
        galleryRef.current.querySelector(`[data-index="${activeImageIndex}"]`).src
      );
    }
  }, [activeImageIndex]);

  return (
    <div className="w-full h-full select-none">
    <div className="text-center">
    <div className="relative flex flex-col items-center py-4">
      <h1 className="text-5xl font-bold leading-tight ">
        <span className="text-secondary">Explore Photos</span>{' '}
      </h1>
    </div>
  </div>
      <div
        className="max-w-7xl mx-auto duration-1000 delay-300 opacity-0 select-none ease animate-fade-in-view"
        style={{
          translate: 'none',
          rotate: 'none',
          scale: 'none',
          opacity: 1,
          transform: 'translate(0px, 0px)',
        }}
      >
        <ul
          ref={galleryRef}
          id="gallery"
          className="grid grid-cols-2 gap-5 lg:grid-cols-5"
        >
          <li>
            <img
              onClick={imageGalleryOpen}
              data-index="1"
              src={am1}
              className="object-cover select-none w-full h-auto bg-gray-200 rounded-[5%] cursor-zoom-in aspect-[5/6] lg:aspect-[2/3] xl:aspect-[3/4]"
              alt="gallery01"
            />
          </li>

          <li>
            <img
              onClick={imageGalleryOpen}
              data-index="1"
              src={am2}
              className="object-cover select-none w-full h-auto bg-gray-200 rounded-[5%] cursor-zoom-in aspect-[5/6] lg:aspect-[2/3] xl:aspect-[3/4]"
              alt="gallery01"
            />
          </li>

          <li>
          <img
            onClick={imageGalleryOpen}
            data-index="1"
            src={am3}
            className="object-cover select-none w-full h-auto bg-gray-200 rounded-[5%] cursor-zoom-in aspect-[5/6] lg:aspect-[2/3] xl:aspect-[3/4]"
            alt="gallery01"
          />
        </li>

        <li>
        <img
          onClick={imageGalleryOpen}
          data-index="1"
          src={am4}
          className="object-cover select-none w-full h-auto bg-gray-200 rounded-[5%] cursor-zoom-in aspect-[5/6] lg:aspect-[2/3] xl:aspect-[3/4]"
          alt="gallery01"
        />
      </li>

      <li>
      <img
        onClick={imageGalleryOpen}
        data-index="1"
        src={am5}
        className="object-cover select-none w-full h-auto bg-gray-200 rounded-[5%] cursor-zoom-in aspect-[5/6] lg:aspect-[2/3] xl:aspect-[3/4]"
        alt="gallery01"
      />
    </li>

    <li>
    <img
      onClick={imageGalleryOpen}
      data-index="1"
      src={am6}
      className="object-cover select-none w-full h-auto bg-gray-200 rounded-[5%] cursor-zoom-in aspect-[5/6] lg:aspect-[2/3] xl:aspect-[3/4]"
      alt="gallery01"
    />
  </li>
         
        </ul>
      </div>
      {imageGalleryOpened && (
        <div
          onClick={imageGalleryClose}
          onKeyDown={(e) => e.keyCode === 27 && imageGalleryClose()}
          className="fixed inset-0 z-[99] flex items-center justify-center bg-black bg-opacity-50 select-none cursor-zoom-out"
        >
          <div className="relative flex items-center justify-center w-11/12 xl:w-4/5 h-11/12">
            <div
              onClick={(e) => {
                e.stopPropagation();
                imageGalleryPrev();
              }}
              className="absolute left-0 flex items-center justify-center text-white translate-x-10 rounded-full cursor-pointer xl:-translate-x-24 2xl:-translate-x-32 bg-white/10 w-14 h-14 hover:bg-white/20"
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </div>
            <img
              style={{ display: 'block' }} // Update display to 'block'
              className="object-contain object-center w-full h-full select-none cursor-zoom-out"
              src={imageGalleryActiveUrl}
              alt=""
            />
            <div
            onClick={(e) => {
                e.stopPropagation();
                imageGalleryNext();
              }}
              className="absolute right-0 flex items-center justify-center text-white -translate-x-10 rounded-full cursor-pointer xl:translate-x-24 2xl:translate-x-32 bg-white/10 w-14 h-14 hover:bg-white/20"
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 19.5l7.5-7.5-7.5-7.5" />
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageGallery;