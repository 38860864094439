/* eslint-disable jsx-a11y/anchor-is-valid */
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { FiFacebook } from 'react-icons/fi';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <div className="text-slate-100 bg-secondary px-[3%] md:px-[6%] pt-10">
      <footer>
        <div className="flex flex-wrap gap-2">
          <div className="flex-1 basis-[10rem] p-2">
            <div className="mt-3">
              <h2 className="text-xl font-semibold">
               Connect with us across our social media. 
              </h2>
              <div className="gap-5 my-6 flex-center-center">
              <Link to="https://www.facebook.com/profile.php?id=61553101094327&mibextid=vk8aRt" target="_blank">
                <div className="icon-box bg-primary hover:bg-hover-color-dark">
                  <FiFacebook />
                </div>
                </Link>

                <Link to="https://x.com/Afrosweden?t=AFExwNDXHdlkdYrLVccFvQ&s=03" target="_blank">
                <div className="icon-box bg-primary hover:bg-hover-color-dark">
                  <FaXTwitter />
                </div>
                </Link>

                <Link to="https://www.instagram.com/afroswedenrealestate?igsh=eG4wNjgzMm11MG1u" target="_blank">
                <div className="icon-box bg-primary hover:bg-hover-color-dark">
                  <FaInstagram />
                </div>
                </Link>
                <Link to="https://www.linkedin.com/company/afrosweden/" target="_blank">
                <div className="icon-box bg-primary hover:bg-hover-color-dark">
                  <FaLinkedin />
                </div>
                </Link>
              </div>
            </div>
          </div>

          {/*  <div className="flex-1 basis-[10rem]">
            <h2 className="text-xl font-semibold">Services</h2>
            <ul>
              <li className="my-3 text-muted">
                <a href="#"> Order Tracking</a>
              </li>
              <li className="my-3 text-muted">
                <a href="#">Whislist</a>
              </li>
              <li className="my-3 text-muted">
                <a href="#">Terms of use</a>
              </li>
              <li className="my-3 text-muted">
                <a href="#">Contact support</a>
              </li>
              <li className="my-3 text-muted">
                <a href="#">2 year guarantee</a>
              </li>
            </ul>
  </div> */}

          <div className="flex-1 basis-[10rem] pl-6">
            <h2 className="text-xl font-semibold">Quick Links</h2>
            <ul>
              <li className="my-3 text-muted">
                <Link to="/">Home</Link>
              </li>
              <li className="my-3 text-muted">
                <Link to="/about">About</Link>
              </li>
              <li className="my-3 text-muted">
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>

          <div className="flex-1 basis-[10rem]">
            <h2 className="text-xl font-semibold">Contact</h2>
            <ul>
              <li className="my-3 text-muted">
              <p><a href="tel:+251978969696">+251 978 96 96 96</a></p>
              </li>
              <li className="my-3 text-muted">
              <p><a href="tel:+251978979797">+251 978 97 97 97</a></p>
              </li>
              <li className="my-3 text-muted">
              <a href="mailto:contact@afrosweden.com">contact@afrosweden.com</a>
              </li>
              <li className="my-3 text-muted">
                <p> Friendship / Bole / Addis Ababa</p>
              </li>
            </ul>
          </div>

          
        </div>
      </footer>
      <div className="py-2 mt-3 text-center border-t text-muted border-dark">
        <p>
        © Copyright 2024. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
