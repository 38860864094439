import React, { useEffect } from 'react';
import img from '../../asssets/afro/Saro-Maria/ASR SM 02.jpg';
import {motion} from 'framer-motion'


const Hero = () => {

 

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const backgroundElement = document.getElementById('background');
      const foregroundElement = document.getElementById('foreground');
      if (backgroundElement && foregroundElement) {
        backgroundElement.style.transform = `translateY(${
          scrollPosition * 0.5
        }px)`;
        foregroundElement.style.transform = `translateY(${
          scrollPosition * 0.8
        }px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="relative h-screen text-white overflow-hidden">
        <div className="absolute inset-0">
          <img
            src={img}
            alt="BackgroundImage"
            className="object-cover object-center w-full h-full"
            id="background"
            loading="lazy"
          />
          <div className="absolute inset-0 bg-black opacity-40"></div>
        </div>

        <div className="animate__animated animate__fadeIn relative z-0 flex-wrap min-h-screen gap-2 md:-mt-10 flex-center-center pt-16 px-[3%] md:px-[6%]">
          <div 
          className="flex-1 basis-[20rem] text-container animate__animated animate__fadeInUp">
            <motion.h1 
             whileInView={{ x: [-100, 0], opacity: [0, 1] }}
             transition={{ duration: 1 }}
            className="text-4xl font-extrabold md:text-5xl">
            Welcome to Afrosweden Real Estate –
            Your Gateway to Luxury Living
            </motion.h1>
            <motion.div 
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 1, delayChildren: 0.6 }}
            className="pl-3 mt-5 border-l-4 border-primary">
              <p>
              Discover the epitome of sophistication with
              our exclusive selection of high-end
              apartments, strategically positioned in
              Addis Ababa, Bole&#39;s most coveted urban
              enclave. Afrosweden Real Estate is not just
              selling a residence; we&#39;re offering the key to
              a lifestyle that surpasses expectations.
              </p>
            </motion.div>
          </div>
          <div className="flex-1 basis-[20rem]">
           {/**  <img src={img} alt="" className="w-full" />*/}
          </div>
          <div id="foreground"></div>
        </div>
      </div>
    </>
  );
};

export default Hero;
