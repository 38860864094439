import React, { useState } from 'react';
import { BiLocationPlus } from 'react-icons/bi';
import { BsPhone } from 'react-icons/bs';
import { FiHeadphones } from 'react-icons/fi';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const templateParams = {
      from_first_name: firstName,
      from_last_name: lastName,
      from_email: email,
      message: message,
    };
  
    try {
      const result = await emailjs.send(
        'service_l1h3046',
        'template_ucus6mg',
        templateParams,
        'UNLvpiObcEfkRNTke'
      );
  
      console.log(result.text);
      // Reset form fields
      setFirstName('');
      setLastName('');
      setEmail('');
      setMessage('');
      toast.success('Message sent successfully!');
    } catch (error) {
      console.error(error.text);
      toast.error('Failed to send message. Please try again later.');
    }
  };

  return (
    <div className="max-w-screen-lg mx-auto p-5">
      <div className="max-w-2xl lg:max-w-4xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold text-gray-900">Contact Us</h2>
       
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 border">
        <div className="bg-[#424241] md:col-span-4 p-10 text-white">
          <h3 className="text-3xl sm:text-4xl leading-normal font-extrabold tracking-tight">
            Get In <span className="text-secondary">Touch</span>
          </h3>
          <p className="mt-4 leading-7 text-gray-100">
          Luxurious living is what you deserve and
          luxury apartments built to the highest
          standards is what we offer. Say, Hello
          and our friendly staff will serve you with
          delight. Be it email, text, whatsapp,
          phone call, DM in our socials or in
          person visit, it is all welcomed with us.
          </p>

          <div className="flex items-center mt-5">
            <BiLocationPlus />
            <span className="text-sm">Addis Ababa, Ethiopia.</span>
          </div>
          <div className="flex items-center mt-5">
            <BsPhone />
            <span className="text-sm"><a href="tel:+251978969696">+251 978 96 96 96</a></span>
          </div>
          <div className="flex items-center mt-5">
            <BsPhone />
            <span className="text-sm"><a href="tel:+251978979797">+251 978 97 97 97</a></span>
          </div>
          <div className="flex items-center mt-5">
            <FiHeadphones />
            <span className="text-sm">24/7</span>
          </div>
        </div>
        <form className="md:col-span-8 p-10" onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-first-name"
              >
                First Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="grid-first-name"
                type="text"
                placeholder="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
             {/*  <p className="text-red-500 text-xs italic">
                Please fill out this field.
              </p> **/}
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Last Name
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name"
                type="text"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-email"
              >
                Email Address
              </label>
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-email"
                type="email"
                placeholder="********@*****.**"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-message"
              >
                Your Message
              </label>
              <textarea
                rows="10"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-message"
                placeholder="Type your message here..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
          </div>

          <button
            type="submit"
            className="bg-primary text-gray-900 hover:bg-primary/30 py-2 px-6 rounded-full text-lg font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
          >
            Send
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ContactUs;