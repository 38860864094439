import React from 'react'

const Map = () => {
  return (
   <>
   <section className="bg-gray-100">
   <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
       <div className="max-w-2xl lg:max-w-4xl mx-auto text-center">
           <h2 className="text-3xl font-extrabold text-gray-900">Visit Our Location</h2>
          
       </div>
       <div className="mt-16 lg:mt-20">
           <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
               <div className="rounded-lg overflow-hidden">
               <iframe
               title="Google Map Location"
               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3107.7835430548764!2d38.78514411525949!3d9.009570793477542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x163f16d5e5a9b051%3A0xf0d6db5aaf35c3da!2sAfro%20Sweden%20Real%20State!5e0!3m2!1sen!2set!4v1631386181219!5m2!1sen!2set"
               width="100%" height="480" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
               </div>
               <div>
                   <div className="max-w-full mx-auto rounded-lg overflow-hidden">
                       <div className="px-6 py-4">
                           <h3 className="text-lg font-medium text-gray-900">Our Address</h3>
                           <p className="mt-1 text-gray-600"> Friendship / Bole / Addis Ababa</p>
                       </div>
                       <div className="border-t border-gray-200 px-6 py-4">
                           <h3 className="text-lg font-medium text-gray-900">Hours</h3>
                           <p className="mt-1 text-gray-600">Monday - Friday: 9am - 5pm</p>
                           <p className="mt-1 text-gray-600">Saturday: 10am - 4pm</p>
                           <p className="mt-1 text-gray-600">Sunday: Closed</p>
                       </div>
                       <div className="border-t border-gray-200 px-6 py-4">
                           <h3 className="text-lg font-medium text-gray-900">Contact</h3>
                           <p className="mt-1 text-gray-600">Email: contact@afrosweden.com</p>
                           <p className="mt-1 text-gray-600">Phone: +251-978969696</p>
                           <p className="mt-1 text-gray-600">Phone: +251-978979797</p>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </div>
</section>
   </>
  )
}

export default Map