import {
  BiBuildingHouse,
  BiFullscreen,
  BiHomeAlt,
  BiShieldAlt2,
} from "react-icons/bi";

import {FaPaintRoller} from 'react-icons/fa'
import { FiHeadphones} from 'react-icons/fi'

import img from '../../asssets/afro/Olompia/ASR O 01.jpg'
import img1 from '../../asssets/afro/Saro-Maria/ASR SM 01.jpg'
import img2 from '../../asssets/afro/Friendship1/ASR F 01.jpg'



export const projects = [
  {
    id: 1,
    name: "Olympia Site",
    number: 14,
    image: img,
    link: "/olympia"
  },
  {
    id: 2,
    name: "Werkeneh Site",
    number: 20,
    image: img2,
    link: "/werkeneh"
  },
  {
    id: 3,
    name: "Tadesech Site",
    number: 24,
    image: img1,
    link: "/tadesech"
  },
  ];


  export const services = [
    {
      id: 1,
      name: "luxury apartment",
      icon: <BiBuildingHouse />,
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum commodi provident est ex similique.",
    },
    {
      id: 2,
      name: "architectural design",
      icon: <BiFullscreen />,
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum commodi provident est ex similique.",
    },
    {
      id: 3,
      name: "extra security",
      icon: <BiShieldAlt2 />,
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum commodi provident est ex similique.",
    },
    {
      id: 4,
      name: "Home remodelling",
      icon: <BiHomeAlt />,
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum commodi provident est ex similique.",
    },
    {
      id: 5,
      name: "office renovation",
      icon: <FaPaintRoller />,
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum commodi provident est ex similique.",
    },
    {
      id: 6,
      name: "24/7 support",
      icon: <FiHeadphones />,
      text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum commodi provident est ex similique.",
    },
  ];

  export const testimonials = [
    {
      id: 1,
      name: "Mrs. Birtukan Abebe",
      role: "Afrosweden Real Estate Home Owner",
      image: img,
      reviewText: "I, Mrs. Birtukan Abebe, had an exceptional experience with Afrosweden Real Estate. Their commitment to delivering on promises in the Ethiopian real estate market is remarkable. From the founders' discipline and attention to detail to their ability to deliver on time, Afrosweden Real Estate stands out. Even during the challenges of the Covid pandemic, they maintained their integrity and earned my trust. My real estate journey with them was truly remarkable.",
    },
    {
      id: 2,
      name: "Mrs. Hiwot Tesgaye",
      role: "Afrosweden Real Estate Home Owner.",
      image:img1,
      reviewText:
        "I, Mrs. Hiwot Tesgaye, am incredibly impressed by Afrosweden Real Estate. Their unwavering commitment to honesty, top-notch build quality, and timely delivery sets them apart in Addis Ababa's real estate landscape. The attention to detail and care they put into crafting my home exceeded my expectations. Afrosweden Real Estate's dedication to excellence is truly commendable, and I hold them in high regard.",
    },
    {
      id: 3,
      name: "Mrs. Emebet Tsegaye",
      role: "Afrosweden Real Estate Home Owner.",
      image: img2,
      reviewText:
        "Representing my brother abroad, I chose Afrosweden Real Estate based on strong recommendations and meticulous research. Impressed by their impeccable finishing quality and high standards, I entrusted them with my brother's dream of owning a home in Ethiopia. Thanks to Afrosweden's unwavering commitment and excellence, his dream has become a reality.",
    },

  ];
  

  