import React, { useState } from "react";
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { FiFacebook } from 'react-icons/fi';
import { motion } from "framer-motion";
import logo from '../../asssets/afro.png'
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);


  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavMenuToggle = () => {
    setIsNavMenuOpen(!isNavMenuOpen);
  };

  const handleClick = () => {
    handleMenuToggle(); // Call the first function
    handleNavMenuToggle(); // Call the second function
  };

  return (
    <nav className='fixed top-0 left-0 right-0 z-50 bg-[#1366A1]'>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center lg:gap-80">
            <div className="flex-shrink-0">
            <Link to="/">
              <img className="h-10 w-42 mb-1" src={logo} alt="Logo" />
              </Link>
            </div>
            <div className="hidden md:block md:ml-8">
              <div className="ml-10 lg:ml-32 flex space-x-4">
                <Link
                  to="/"
                  className="text-white hover:bg-[#fde047] hover:text-black px-3 py-2 rounded-md text-md font-bold"
                >
                  Home
                </Link>
                <div className="relative inline-block text-left">
                <button
                  type="button"
                  className="text-white hover:bg-[#fde047] hover:text-black px-3 py-2 rounded-md text-md font-bold"
                  onClick={handleNavMenuToggle}
                >
                  Project
                </button>
                {isNavMenuOpen && (
                  <div className="origin-top-left absolute left-0 mt-2 -ml-1 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      
                      <Link
                        to="/olympia"
                        onClick={handleNavMenuToggle}
                        className="block px-4 py-2 text-md text-gray-700 hover:bg-[#fde047] hover:text-gray-900"
                        role="menuitem"
                      >
                        Olompia
                      </Link>

                      <Link
                      to="/werkeneh"
                      className="block px-4 py-2 text-md text-gray-700 hover:bg-[#fde047] hover:text-gray-900"
                      role="menuitem"
                      onClick={handleNavMenuToggle}
                    >
                      Werkeneh Site
                    </Link>

                      <Link
                        to="/tadesech"
                        className="block px-4 py-2 text-md text-gray-700 hover:bg-[#fde047] hover:text-gray-900"
                        role="menuitem"
                        onClick={handleNavMenuToggle}
                      >
                        Tadesech Site
                      </Link>
                      <Link
                        to="/nadew"
                        className="block px-4 py-2 text-md text-gray-700 hover:bg-[#fde047] hover:text-gray-900"
                        role="menuitem"
                        onClick={handleNavMenuToggle}
                      >
                      Nadew Site
                      </Link>
                      <Link
                        to="/yeshi"
                        className="block px-4 py-2 text-md text-gray-700 hover:bg-[#fde047] hover:text-gray-900"
                        role="menuitem"
                        onClick={handleNavMenuToggle}
                      >
                        Yeshi Site
                      </Link>
                    </div>
                  </div>
                )}
              </div>
                <Link
                  to="/about"
                  className="text-white hover:bg-[#fde047] hover:text-black px-3 py-2 rounded-md text-md font-bold"
                >
                  About
                </Link>
                <Link
                  to="/contact"
                  className="text-white hover:bg-[#fde047] hover:text-black px-3 py-2 rounded-md text-md font-bold"
                >
                 Contact
                </Link>
                {/* Add more menu items here */}
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={handleMenuToggle}
              type="button"
              className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isMenuOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="md:hidden h-screen"
          id="mobile-menu"
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
           <Link
                  onClick={handleMenuToggle}
                  to="/"
                  className="text-white hover:bg-[#fde047] hover:text-black px-3 py-2 rounded-md text-md font-bold  block text-base font-20"
                >
                  Home
                </Link>
              
                <Link
                  onClick={handleMenuToggle}
                  to="/about"
                  className="text-white hover:bg-[#fde047] hover:text-black px-3 py-2 rounded-md text-md font-bold  block text-base font-20"
                >
                  About
                </Link>
                <Link
                  onClick={handleMenuToggle}
                  to="/contact"
                  className="text-white hover:text-black px-3 py-2 rounded-md text-md font-bold hover:bg-[#fde047] block text-base font-20"
                >
                 Contact
                </Link>

                <div className="relative inline-block text-left">
                <button
                  type="button"
                  className="text-white hover:bg-[#fde047] hover:text-black px-3 py-2 rounded-md text-md font-bold  block text-base font-20"
                  onClick={handleNavMenuToggle}
                >
                  Project
                </button>
                {isNavMenuOpen && (
                  <div className="z-50 origin-top-left absolute left-0 mt-2 -ml-1 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <Link
                    to="/olympia"
                    className="block px-4 py-2 text-md text-gray-700 hover:bg-[#fde047] hover:text-gray-900"
                    role="menuitem"
                    onClick={handleClick}
                  >
                    Olompia
                  </Link>

                      <Link
                        to="/werkeneh"
                        className="block px-4 py-2 text-md text-gray-700 hover:bg-[#fde047] hover:text-gray-900"
                        role="menuitem"
                        onClick={handleClick}
                      >
                        Werkeneh Site
                      </Link>
                      
                      <Link
                      onClick={handleClick}
                      to="/tadesech"
                      className="block px-4 py-2 text-md text-gray-700 hover:bg-[#fde047] hover:text-gray-900"
                      role="menuitem"
                    >
                      Tadesech Site
                    </Link>
                    <Link
                        to="/nadew"
                        className="block px-4 py-2 text-md text-gray-700 hover:bg-[#fde047] hover:text-gray-900"
                        role="menuitem"
                        onClick={handleClick}
                      >
                      Nadew Site
                      </Link>
                      <Link
                        to="/yeshi"
                        className="block px-4 py-2 text-md text-gray-700 hover:bg-[#fde047] hover:text-gray-900"
                        role="menuitem"
                        onClick={handleClick}
                      >
                        Yeshi Site
                      </Link>
                    </div>
                  </div>
                )}
              </div>
           
            {/* Add more menu items here */}
          </div>
          <div className="flex-1 basis-[10rem] p-2 z-0">
            {/*<Link to="/" className="flex-shrink-0 flex-align-center gap-x-1">
              <img src={logo} alt="logo" />
                </Link> */}
            <div className="mt-3">
              <div className="gap-5 my-6 flex-center-center">
              <Link to="https://www.facebook.com/profile.php?id=61553101094327&mibextid=vk8aRt" target="_blank">
                <div className="icon-box bg-primary hover:bg-hover-color-dark">
                  <FiFacebook />
                </div>
                </Link>

                <Link to="https://x.com/Afrosweden?t=AFExwNDXHdlkdYrLVccFvQ&s=03" target="_blank">
                <div className="icon-box bg-primary hover:bg-hover-color-dark">
                  <FaXTwitter />
                </div>
                </Link>

                <Link to="https://www.instagram.com/afroswedenrealestate?igsh=eG4wNjgzMm11MG1u" target="_blank">
                <div className="icon-box bg-primary hover:bg-hover-color-dark">
                  <FaInstagram />
                </div>
                </Link>

                <Link to="https://www.linkedin.com/company/afrosweden/" target="_blank">
                <div className="icon-box bg-primary hover:bg-hover-color-dark">
                  <FaLinkedin />
                </div>
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </nav>
  );
};

export default Navbar;