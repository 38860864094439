import React from 'react';
import img from '../../asssets/afro/Saro-Maria/ASR SM 01.jpg';

const AboutUs = () => {
  return (
    <section className="flex items-center py-5 xl:h-screen">
      <div className="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
        <div className="flex flex-wrap ">
          <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
            <div className="relative">
              <img
                src={img}
                alt=""
                className="relative z-40 object-cover w-full h-96 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded"
              />
              <div className="absolute z-10 hidden w-full h-full bg-secondary rounded-bl-[80px] rounded -bottom-6 right-6 lg:block"></div>
            </div>
          </div>
          <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0 ">
            <div className="relative">
             
              <h1 className="heading">We build palaces, fit for Royals</h1>
            </div>
            <p className="mt-6 mb-10 text-base leading-7 text-gray-900">
            Our journey started when our founders who
            spent many years living in Sweden, hoped
            to purchase real estate in their
            homeland. Unfortunately, the continual
            disappointment they faced in building
            quality, delivery time, unfulfilled promises,
            contract breaches, and much more, were
            extremely frustrating. </p>
              <p className="mt-6 mb-10 text-base leading-7 text-gray-900">
              This led to our four founders joining their
              expertise to bring the same level of
              absolute quality and pride in craftsmanship
              they admired in the Swedish real estate
              industry. Thus, giving birth to Afrosweden
              Real Estate PLC.</p>

             <p className="mt-6 mb-10 text-base leading-7 text-gray-900"> 
             When we launched our project our foremost
             priority was that our clients can relax in
             peace from the moment they make their
             initial installment, knowing a capable &amp;
             responsible company is building their
             Palaces on their behalf. We are proud to
             say we have accomplished this from our
             get-go and it is still our highest priority that
             our clients lead a stress free life with all
             their concerns and needs taken care of by us.</p>
           
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
