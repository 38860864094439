import React, {useEffect} from 'react'
import Hero from '../components/Home/Hero'
import Projects from '../components/Home/Project'
import Video from '../components/Home/Video'
import Testimonials from '../components/Home/Testimonials'
import ContactUs from '../components/Home/ContactUs'
import About from '../components/Home/About'

const Home = () => {
  const videoId = 'VY6Ba9feXck';
 


  useEffect(() => {
    (async () => {
      const LocomotiveScroll = (await import('locomotive-scroll')).default;
      const locomotiveScroll = new LocomotiveScroll();
    })();
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
 
  return (
    <>  
      <Hero />
      <div className="pt-16 px-[1%] md:px-[2%]">
        <Projects />
        <About />
        <Video videoId={videoId} />
        <Testimonials />
        <ContactUs />
      </div>
    
   </>
  )
}

export default Home